<template>
    <div v-if="scopedProjectData" class="md:max-w-screen-xl w-full mx-auto">
        <div class="my-10 flex flex-wrap px-5">
            <div
                v-if="scopedProjectData.participants.length"
                class="text-4xl font-normal md:flex-nowrap mb-5 md:mb-0 w-full md:w-auto"
            >
                Lista invitati
            </div>
            <div class="grow"></div>
            <div
                v-if="showAddUserButton"
                @click="openAddUserModal()"
                class="btn btn-primary mb-5 md:mr-5 md:mb-0 w-full md:w-auto"
            >
                <font-awesome-icon icon="fa-solid fa-user-plus" /> Adauga
                invitat
            </div>

            <div
                @click="openMigrateUserModal()"
                class="btn btn-primary mb-5 md:mb-0 w-full md:w-auto"
            >
                <font-awesome-icon icon="fa-solid fa-copy" /> Copiaza invitati
            </div>
        </div>

        <table-lite
            v-if="scopedProjectData.participants.length"
            :is-slot-mode="true"
            :is-static-mode="true"
            :is-loading="loading"
            :columns="columns"
            :rows="scopedProjectData.participants"
            :messages="messages"
            :total="scopedProjectData.participants.length"
            :sortable="sortable"
        >
            <template v-slot:recipient_name="data">
                {{ data.value.recipient_name }}
            </template>

            <template v-slot:invitation_id="data">
                <button
                    @click="openEditModal(data.value)"
                    class="btn btn-square btn-outline btn-secondary mb-3 mr-3 tooltip"
                    data-tip="Modifica"
                >
                    <font-awesome-icon icon="fa-solid fa-user-pen" />
                </button>
                <button
                    @click="openDeleteModal(data.value)"
                    class="btn btn-square btn-outline btn-accent tooltip"
                    data-tip="Sterge"
                >
                    <font-awesome-icon icon="fa-solid fa-user-xmark" />
                </button>
            </template>
        </table-lite>

        <p
            v-if="
                scopedProjectData.participants &&
                scopedProjectData.participants.length === 0
            "
            class="text-gray-500 mt-4"
        >
            Nu ai nici un invitat in acest proiect.
        </p>
        <div>
            <ModalComponent
                :is-open="isAddUserModalOpen"
                @close="closeAddUserModal"
            >
                <!-- Content for Modal 1 -->

                <h3 class="font-bold text-lg">Adauga Invitat</h3>
                <AddParticipantForm
                    :projectId="projectId"
                    :close-modal="closeAddUserModal"
                />
                <div class="modal-action">
                    <form method="dialog">
                        <button
                            @click="closeAddUserModal"
                            class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                        >
                            ✕
                        </button>
                    </form>
                </div>
            </ModalComponent>

            <ModalComponent
                :is-open="isDeleteModalOpen"
                @close="closeDeleteModal"
            >
                <!-- Content for Modal 1 -->

                <h3 class="font-bold text-lg">Sterge Invitat</h3>
                <p class="py-4"></p>
                <p>
                    Urmeaza sa stergi invitatul
                    <span class="font-bold"
                        >{{ selectedDeleteParticipant.recipient_name }}
                        {{ selectedDeleteParticipant.recipient_surname }}</span
                    >.
                </p>
                <p>Aceasta actiune este ireversibila.</p>
                <div class="modal-action">
                    <form method="dialog">
                        <button
                            @click="closeDeleteModal"
                            class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                        >
                            ✕
                        </button>

                        <button class="btn mr-5" @click="closeDeleteModal">
                            Anuleaza
                        </button>
                        <button
                            @click="
                                deleteParticipant(
                                    selectedDeleteParticipant.unique_parameter
                                )
                            "
                            class="btn btn-accent"
                        >
                            Am inteles, sterge invitatul
                        </button>
                    </form>
                </div>
            </ModalComponent>

            <!-- Add/Edit Modal Content -->
            <ModalComponent :is-open="isEditModalOpen" @close="closeEditModal">
                <h3 class="font-bold text-lg">Modificare Date Invitat</h3>
                <button
                    @click="closeEditModal"
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                    ✕
                </button>
                <div class="py-4">
                    <div>
                        <label
                            for="editName"
                            class="input input-bordered flex items-center gap-2 mb-5"
                            >Nume:<input
                                class="grow"
                                v-model="editedParticipant.recipient_name"
                                id="editName"
                                type="text"
                                required
                        /></label>

                        <label
                            for="editSurname"
                            class="input input-bordered flex items-center gap-2 mb-5"
                            >Prenume:<input
                                class="grow"
                                v-model="editedParticipant.recipient_surname"
                                id="editSurname"
                                type="text"
                                required
                        /></label>

                        <label
                            for="editMobile"
                            class="input input-bordered flex items-center gap-2 mb-5"
                            >Mobil:
                            <input
                                class="grow"
                                v-model="editedParticipant.recipient_mobile"
                                id="editMobile"
                                type="text"
                                required
                        /></label>

                        <label
                            for="editEmail"
                            class="input input-bordered flex items-center gap-2 mb-5"
                            >Email:
                            <input
                                class="grow"
                                v-model="editedParticipant.recipient_email"
                                id="editEmail"
                                type="email"
                                required
                        /></label>

                        <button
                            class="btn mr-5"
                            @click.prevent="closeEditModal"
                        >
                            Anuleaza
                        </button>
                        <button
                            @click="updateParticipant()"
                            class="btn btn-primary mt-4"
                        >
                            Salveaza
                        </button>
                    </div>
                </div>
            </ModalComponent>

            <!-- Migrate User Modal Content -->
            <ModalComponent
                v-if="isMigrateUserModalOpen"
                :is-open="isMigrateUserModalOpen"
                @close="closeMigrateUserModal"
            >
                <h3 class="font-bold text-lg">
                    Copiaza invitati din alt proiect
                </h3>
                <button
                    @click="closeMigrateUserModal"
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                    ✕
                </button>
                <div class="py-4">
                    <MigrateParticipant
                        :projectId="projectId"
                        :close-modal="closeMigrateUserModal"
                    />
                </div>
            </ModalComponent>
        </div>

        <div v-if="loading">
            <LoadingComponent></LoadingComponent>
        </div>
    </div>
</template>

<script>
import TableLite from 'vue3-table-lite';
import ModalComponent from '@/components/ModalComponent.vue';
import LoadingComponent from './LoadingComponent.vue';
import { useToast } from 'vue-toastification';
import AddParticipantForm from './AddParticipantForm.vue';
import MigrateParticipant from './MigrateParticipant.vue';
import { mapGetters } from 'vuex';

export default {
    props: ['projectId'],
    components: {
        TableLite,
        ModalComponent,
        LoadingComponent,
        AddParticipantForm,
        MigrateParticipant,
    },
    data() {
        return {
            isMigrateUserModalOpen: false,
            isAddUserModalOpen: false,
            isDeleteModalOpen: false,
            selectedDeleteParticipant: {},
            isEditModalOpen: false,
            editedParticipant: {},
            showAddUserButton: false,
            columns: [
                {
                    label: 'Nume',
                    field: 'recipient_name',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Prenume',
                    field: 'recipient_surname',
                    width: '10%',
                    sortable: true,
                },
                {
                    label: 'Mobile',
                    field: 'recipient_mobile',
                    width: '10%',
                    sortable: false,
                },
                {
                    label: 'Email',
                    field: 'recipient_email',
                    width: '15%',
                    sortable: false,
                },
                {
                    label: 'URL Personal',
                    field: 'unique_parameter',
                    width: '5%',
                    sortable: false,
                    display: function (row) {
                        // Get the current URL
                        const currentURL = window.location.href;

                        // Use URL API to parse the URL
                        const url = new URL(currentURL);

                        // Extract and return the root domain
                        return `<a href="${url.origin}/#/public/${row.project_id}/${row.unique_parameter}" class="underline" target="_blank">Link</a>`;
                    },
                },
                {
                    label: 'Actiuni',
                    field: 'invitation_id',
                    width: '12%',
                    sortable: false,
                },
            ],
            sortable: {
                order: 'recipient_name',
                sort: 'desc',
            },
            messages: {
                pagingInfo:
                    'Se afiseaza {0} din {1} rezultate | Total {2} rezultate',
                pageSizeChangeLabel: 'Numar randuri afisate: ',
                gotoPageLabel: ' Mergi la pagina: ',
                noDataAvailable: 'Nu sunt disponibile informatii',
            },
        };
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    mounted() {},
    computed: {
        ...mapGetters([
            'projects',
            'plan',
            'loading',
            'scopedProjectData',
            'stats',
        ]),
    },
    methods: {
        openDeleteModal(participant) {
            this.isDeleteModalOpen = true;
            this.selectedDeleteParticipant = participant;
        },
        closeDeleteModal() {
            this.isDeleteModalOpen = false;
        },
        openAddUserModal() {
            this.isAddUserModalOpen = true;
        },
        closeAddUserModal() {
            this.isAddUserModalOpen = false;
        },
        openMigrateUserModal() {
            this.isMigrateUserModalOpen = true;
        },
        closeMigrateUserModal() {
            this.isMigrateUserModalOpen = false;
        },
        openEditModal(participant) {
            this.isEditModalOpen = true;
            this.editedParticipant = {
                invitation_id: participant.invitation_id,
                project_id: participant.project_id,
                recipient_name: participant.recipient_name,
                recipient_surname: participant.recipient_surname,
                recipient_mobile: participant.recipient_mobile,
                recipient_email: participant.recipient_email,
                unique_parameter: participant.unique_parameter,
            };
        },
        closeEditModal() {
            this.isEditModalOpen = false;
        },
        async updateParticipant() {
            try {
                // Dispatch the 'updateParticipant' action
                await this.$store.dispatch(
                    'updateParticipant',
                    this.editedParticipant
                );
                this.toast.success('Detalii actualizate cu succes!');
                this.closeEditModal();
            } catch (error) {
                this.toast.error(`A aparut o eroare ${error}`);
                // console.error('Error updating participant:', error);
            }
        },
        async deleteParticipant(participantId) {
            this.closeDeleteModal();
            const projectId = this.projectId;

            try {
                await this.$store.dispatch('deleteParticipant', {
                    participantId,
                    projectId,
                });

                this.toast.success(
                    `${this.selectedDeleteParticipant.recipient_name} a fost sters din lista de invitati!`
                );
            } catch (error) {
                this.toast.error(
                    `${this.selectedDeleteParticipant.recipient_name} nu a putut fi sters`
                );
            }
        },
    },
};
</script>

<style>
/* Add any styling specific to this component */

.participantsList table {
    border-radius: 20px;
    border: 1px solid #cecece;
}
</style>
