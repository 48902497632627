<template>
    <div class="home">
        <div class="max-w-screen-xl mt-24 px-8 xl:px-16 mx-auto" id="about">
            <div>
                <div
                    class="grid grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16"
                    style="opacity: 1; transform: none"
                >
                    <div
                        class="flex flex-col justify-center items-start row-start-2 sm:row-start-1"
                    >
                        <h1
                            class="text-3xl lg:text-4xl xl:text-5xl font-medium text-black-600 leading-normal"
                        >
                            Pregatiri mai usoare cu
                            <strong>Invitatii Smart</strong>
                            .
                        </h1>

                        <p class="text-black-500 mt-4 mb-6">
                            Economisește Timp! Partajează instantaneu
                            invitațiile tale online, fără procese de imprimare
                            sau trimitere poștală.
                        </p>

                        <RouterLink to="/register" class="mx-auto">
                            <button
                                class="btn btn-lg py-3 lg:py-4 px-12 lg:px-16 text-white-500 font-semibold rounded-lg bg-orange-500 hover:shadow-orange-md transition-all outline-none undefined"
                            >
                                Incearca Gratuit!
                            </button>
                        </RouterLink>
                    </div>

                    <div class="flex w-full">
                        <div
                            class="h-full w-full"
                            style="opacity: 1; transform: none"
                        >
                            <span>
                                <span></span>

                                <img
                                    alt="Invitatii Smart"
                                    src="images/invitatii-banner-1-crop.png"
                                    sizes="100vw"
                                />

                                <noscript>
                                    <img
                                        alt="Invitatii Smart"
                                        sizes="100vw"
                                        src="images/invitatii-banner-1-crop.png"
                                    />
                                </noscript>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative w-full flex">
                <div
                    class="rounded-lg w-full grid grid-flow-row grid-cols-3 py-9 sm:divide-y-0 divide-x-2 divide-gray-100 bg-white-500 z-10"
                >
                    <div
                        class="flex items-center justify-center py-4 sm:py-6 md:px-4"
                    >
                        <div
                            class="flex flex-col md:flex-row items-center justify-center"
                        >
                            <div
                                class="flex items-center justify-center bg-orange-100 h-10 w-10 md:w-12 md:h-12 md:mr-6 rounded-full"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-paper-plane"
                                    class="md:h-6 md:w-6"
                                />
                            </div>

                            <div class="flex flex-col">
                                <p
                                    class="text-sm md:text-xl text-black-600 font-bold"
                                >
                                    Partajare
                                </p>

                                <p class="text-sm md:text-lg text-black-500">
                                    Automata
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex items-center justify-center py-4 sm:py-6 md:px-4"
                    >
                        <div
                            class="flex flex-col md:flex-row items-center justify-center"
                        >
                            <div
                                class="flex items-center justify-center bg-orange-100 h-10 w-10 md:w-12 md:h-12 md:mr-6 rounded-full"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-fingerprint"
                                    class="h-6 w-6"
                                />
                            </div>

                            <div class="flex flex-col">
                                <p
                                    class="text-sm md:text-xl text-black-600 font-bold"
                                >
                                    Modele
                                </p>

                                <p class="text-sm md:text-lg text-black-500">
                                    Personalizate
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex items-center justify-center py-4 sm:py-6 md:px-4"
                    >
                        <div
                            class="flex flex-col md:flex-row items-center justify-center"
                        >
                            <div
                                class="flex items-center justify-center bg-orange-100 h-10 w-10 md:w-12 md:h-12 md:mr-6 rounded-full"
                            >
                                <font-awesome-icon
                                    icon="fa-solid fa-chart-pie"
                                    class="h-6 w-6"
                                />
                            </div>

                            <div class="flex flex-col">
                                <p
                                    class="text-sm md:text-xl text-black-600 font-bold"
                                >
                                    Statistici
                                </p>

                                <p class="text-sm md:text-lg text-black-500">
                                    Avansate
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeView',
    components: {},
};
</script>
