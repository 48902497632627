<template>
    <div>
        <div class="">
            <div class="my-8 grid grid-cols-2 md:grid-cols-2 gap-4">
                <label class="form-control">
                    <div class="label">
                        <span class="label-text">Nume</span>
                    </div>
                    <input
                        type="text"
                        v-model="name"
                        placeholder="Nume"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <label class="form-control">
                    <div class="label">
                        <span class="label-text">Prenume</span>
                    </div>
                    <input
                        type="text"
                        v-model="surname"
                        placeholder="Prenume"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <label class="form-control">
                    <div class="label">
                        <span class="label-text">Mobil</span>
                    </div>
                    <input
                        type="text"
                        v-model="mobile"
                        placeholder="Mobil"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <label class="form-control">
                    <div class="label">
                        <span class="label-text">Email</span>
                    </div>
                    <input
                        type="email"
                        v-model="email"
                        placeholder="Email"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>
            </div>
        </div>
        <button class="btn mr-5" @click.prevent="closeAddUserModal()">
            Anuleaza
        </button>
        <button @click.prevent="addParticipant()" class="btn btn-primary">
            <font-awesome-icon icon="fa-solid fa-user-plus" />
            Adauga
        </button>
    </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import eventBus from '@/utils/EventBus';
import { handleDeauth } from '@/utils/auth';

export default {
    data() {
        return {
            name: '',
            surname: '',
            mobile: '',
            email: '',
            isAuthenticated: false,
            uniqueParameter: '',
        };
    },
    props: {
        projectId: {
            required: true,
        },
        closeModal: {
            type: Function,
            required: true,
        },
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    created() {
        // Check if the user is already authenticated (e.g., on page reload)
        this.isAuthenticated = this.checkAuthentication();
        // You may want to add a global event listener to listen for authentication changes
        window.addEventListener('storage', this.handleStorageEvent);
    },
    methods: {
        closeAddUserModal() {
            this.closeModal(); // Notify the parent to close the modal
        },
        async addParticipant() {
            try {
                // Retrieve user_id from localStorage
                const jwtToken = localStorage.getItem('userToken');

                // Assuming you have form data available in Vue data properties
                const formData = {
                    name: this.name,
                    surname: this.surname,
                    mobile: this.mobile,
                    email: this.email,
                    projectId: this.projectId,
                };

                await axios
                    .post(
                        '/api/index.php?endpoint=addParticipant',
                        formData, // Pass form data in the request payload
                        {
                            headers: {
                                'Content-Type':
                                    'application/x-www-form-urlencoded',
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((result) => {
                        if (result.data.success) {
                            this.toast.success(
                                `${this.name} a fost adaugat in lista de invitati!`
                            );

                            // Notify the parent that a participant was added
                            this.closeModal(true);

                            // Emit an event after the success toast
                            if (
                                result &&
                                result.data &&
                                result.data.insertedRow
                            )
                                eventBus.emit(
                                    'AddedNewParticipant',
                                    result.data.insertedRow
                                );
                        } else {
                            if (
                                result.data.message == 'Token validation error'
                            ) {
                                handleDeauth(this);
                            }
                            this.toast.error(
                                `${this.name} nu a putut fi adaugat >  ${result.data.message}`
                            );
                        }
                    })
                    .catch((error) => {
                        this.toast.error(
                            `${this.name} nu a putut fi adaugat! ${error}`
                        );
                    });
            } catch (error) {
                console.error(
                    'Error adding new user:',
                    error.response ? error.response.data : error.message
                );
            }
        },
        checkAuthentication() {
            // Check if the authentication token is present in localStorage
            const token = localStorage.getItem('userToken');
            return !!token; // Returns true if the token is present, otherwise false
        },
        handleStorageEvent(event) {
            // Listen for changes in localStorage (e.g., logout in another tab)
            if (event.key === 'userToken') {
                this.isAuthenticated = this.checkAuthentication();
            }
        },
    },
};
</script>

<style scoped>
/* No need to add additional styles here for Tailwind CSS */
</style>
