<template>
    <div
        class="md:max-w-screen-xl px-6 sm:px-8 lg:px-16 md:mx-auto grid grid-flow-col py-3 sm:py-4 bg-primary rounded-3xl shadow-xl"
    >
        <div class="">
            <h3 class="mt-10 text-2xl font-normal">Adauga invitati</h3>
            <div
                class="my-8 flex flex-wrap md:flex-nowrap items-end justify-center"
            >
                <label class="form-control w-full max-w-xs md:mr-5">
                    <div class="label">
                        <span class="label-text">Nume</span>
                    </div>
                    <input
                        type="text"
                        v-model="name"
                        placeholder="Nume"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <label class="form-control w-full max-w-xs md:mr-5">
                    <div class="label">
                        <span class="label-text">Prenume</span>
                    </div>
                    <input
                        type="text"
                        v-model="surname"
                        placeholder="Prenume"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <label class="form-control w-full max-w-xs md:mr-5">
                    <div class="label">
                        <span class="label-text">Mobil</span>
                    </div>
                    <input
                        type="text"
                        v-model="mobile"
                        placeholder="Mobil"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <label class="form-control w-full max-w-xs md:mr-5">
                    <div class="label">
                        <span class="label-text">Email</span>
                    </div>
                    <input
                        type="email"
                        v-model="email"
                        placeholder="Email"
                        required
                        class="input input-bordered w-full max-w-xs"
                    />
                </label>

                <button
                    @click.prevent="addParticipant()"
                    class="btn btn-secondary mt-10 w-full md:w-auto"
                >
                    <font-awesome-icon icon="fa-solid fa-user-plus" />
                    Adauga
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
    data() {
        return {
            name: '',
            surname: '',
            mobile: '',
            email: '',
            mobilePattern: /^0\d{9}$/,
            isAuthenticated: false,
            uniqueParameter: '',
        };
    },
    props: {
        projectId: {
            required: true,
        },
    },
    setup() {
        // Get toast interface
        const toast = useToast();

        // Make it available inside methods
        return { toast };
    },
    created() {},
    methods: {
        async addParticipant() {
            // Check if the mobile number matches the desired format
            if (!this.mobilePattern.test(this.mobile)) {
                this.toast.error(
                    'Formatul numarului de telefon mobil nu este valid. Exemplu: 0712345678'
                );
                return;
            }

            // Assuming you have form data available in Vue data properties
            const formData = {
                name: this.name,
                surname: this.surname,
                mobile: this.mobile,
                email: this.email,
                projectId: this.projectId,
            };

            try {
                await this.$store.dispatch('addParticipant', formData);

                this.toast.success(
                    `${formData.name} a fost adaugat in lista de invitati!`
                );
            } catch (error) {
                this.toast.error(`A aparut o eroare.`);
            }
        },
    },
};
</script>

<style scoped>
/* No need to add additional styles here for Tailwind CSS */
</style>
