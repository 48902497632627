<template>
    <div v-if="editMode">
        <div class="tooltip tooltip-right" data-tip="Numaratoare Inversa">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg"
                v-if="
                    !section.countdownActive ||
                    section.countdownActive === false
                "
                @click="addCountdownTimer"
            >
                <font-awesome-icon icon="fa-solid fa-hourglass-half" />
            </button>

            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg btn-accent"
                v-else
                @click="removeCountdownTimer"
            >
                <font-awesome-icon icon="fa-solid fa-hourglass-half" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        section: {
            type: Object,
            required: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isEditing: false,
            editedValue: this.value,
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        addCountdownTimer() {
            this.$emit('update-section', this.sectionKey, {
                ...this.section,
                countdownActive: true,
            });
        },
        removeCountdownTimer() {
            const updatedSection = { ...this.section };
            delete updatedSection.countdownActive;
            this.$emit('update-section', this.sectionKey, updatedSection);
        },
    },
};
</script>

<style scoped></style>
