<template>
    <div v-if="editMode">
        <ModalComponent :is-open="isModalOpen" @close="closeModal">
            <h3 class="font-bold text-lg">Modifica Pozitie Sectiune</h3>
            <button
                @click="closeModal"
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
                ✕
            </button>
            <div class="py-4">
                <div>
                    <input
                        type="number"
                        v-model.number="sectionOrderLocal"
                        class="input input-bordered w-full max-w-xs"
                        min="1"
                        :max="sectionCount"
                    />
                </div>
            </div>
            <div class="modal-action">
                <button class="btn btn-success" @click="updateOrder">
                    Salveaza
                </button>
                <button class="btn" @click="closeModal">Anuleaza</button>
            </div>
        </ModalComponent>

        <div class="tooltip tooltip-right" data-tip="Modifica Pozitie Sectiune">
            <button
                class="btn btn-square btn-sm md:btn-md md:text-lg"
                @click="openModal"
                :class="isEditing ? 'btn-accent' : ''"
            >
                <font-awesome-icon icon="fa-solid fa-arrow-down-1-9" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalComponent from '@/components/ModalComponent.vue';

export default {
    props: {
        section: {
            type: Object,
            required: true,
        },
        sectionKey: {
            type: String,
            required: true,
        },
        sectionCount: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isEditing: false,
            isModalOpen: false,
            sectionOrderLocal: 0,
        };
    },
    components: {
        ModalComponent,
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        openModal() {
            this.isModalOpen = true;
            this.sectionOrderLocal =
                this.section.order !== undefined ? this.section.order : 0;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        updateOrder() {
            this.closeModal();
            this.$emit('update-order', this.sectionKey, this.sectionOrderLocal);
        },
    },
};
</script>

<style scoped></style>
