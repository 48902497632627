<template>
    <div
        class="ml-5 md:ml-12 z-40 fixed transition-all"
        :class="
            editMode ? 'mt-20 md:mt-24 opacity-100' : 'mt-5 md:mt-10 opacity-0'
        "
    >
        <div class="tooltip tooltip-right" data-tip="Adauga Sectiune">
            <button
                class="btn btn-sm btn-circle btn-warning"
                @click="addSection"
            >
                <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import axios from 'axios';

export default {
    props: {},
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        async addSection() {
            try {
                // Fetch the schema from the local file
                // const response = await axios.get('./template_schema.json');
                // const schema = response.data.definitions.section;
                const nextSectionKey = this.generateRandomString(4);
                // Generate a new section based on the schema
                const newSection = {
                    _sectionDisplay: `Section ${nextSectionKey}_custom`,
                    _sectionType: {
                        _displayName: 'Section Type',
                        value: 'text',
                    },
                    order: 0,
                    title: {
                        text: {
                            _config: {
                                _displayName: 'Title',
                                _inputType: 'text',
                            },
                            value: 'New Title',
                        },
                        fontFamily: {
                            _config: {
                                _displayName: 'Font',
                                _inputType: 'select',
                            },
                            _value: "'Dancing Script', cursive",
                        },
                        fontSize: {
                            _config: {
                                _displayName: 'Font Size',
                                _inputType: 'range',
                                _min: '9',
                                _max: '300',
                            },
                            value: '40',
                        },
                        textColor: {
                            _config: {
                                _displayName: 'Text Color',
                                _inputType: 'color',
                            },
                            value: '#000000',
                        },
                        marginTop: {
                            _config: {
                                _displayName: 'Margin Top',
                                _inputType: 'range',
                                _min: '0',
                                _max: '70',
                            },
                            value: '10',
                        },
                        _CSSclass: {
                            _config: {
                                _displayName: 'CSS Class',
                                _inputType: 'text',
                            },
                            value: 'new-class',
                        },
                    },
                    description: {
                        text: {
                            _config: {
                                _displayName: 'Description',
                                _inputType: 'text',
                            },
                            value: 'New Description',
                        },
                        fontFamily: {
                            _config: {
                                _displayName: 'Font',
                                _inputType: 'select',
                            },
                            _value: "'Dancing Script', cursive",
                        },
                        fontSize: {
                            _config: {
                                _displayName: 'Font Size',
                                _inputType: 'range',
                                _min: '9',
                                _max: '300',
                            },
                            value: '20',
                        },
                        textColor: {
                            _config: {
                                _displayName: 'Text Color',
                                _inputType: 'color',
                            },
                            value: '#000000',
                        },
                        marginTop: {
                            _config: {
                                _displayName: 'Margin Top',
                                _inputType: 'range',
                                _min: '0',
                                _max: '70',
                            },
                            value: '5',
                        },
                        _CSSclass: {
                            _config: {
                                _displayName: 'CSS Class',
                                _inputType: 'text',
                            },
                            value: 'new-class',
                        },
                    },
                    backgroundColor: {
                        _config: {
                            _displayName: 'Background Color',
                            _inputType: 'color',
                        },
                        value: '#ffffff',
                    },
                    backgroundImage: {
                        _config: {
                            _displayName: 'Background Image',
                            _inputType: 'image',
                        },
                        value: '',
                    },
                    minHeight: {
                        _config: {
                            _displayName: 'Min Height',
                            _inputType: 'select',
                            _selectOptions: ['50vh', '100vh'],
                        },
                        _value: '100vh',
                    },
                    _CSSclass: {
                        _config: {
                            _displayName: 'CSS Class',
                            _inputType: 'text',
                        },
                        value: 'new-section',
                    },
                };

                // Emit the new section to the parent component
                this.$emit('add-section', nextSectionKey, newSection);
            } catch (error) {
                console.error('Error loading schema:', error);
            }
        },
        generateRandomString(length) {
            let result = '';
            const characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            }
            return result;
        },
    },
};
</script>

<style scoped></style>
