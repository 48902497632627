import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { checkAuthentication } from '../utils/auth'; // Import checkAuthentication
import ProjectDetails from '../views/ProjectDetails.vue';
import PublicInvitation from '../views/PublicInvitation.vue';
import ProjectDetailsInvitations from '../views/ProjectDetailsInvitations.vue';
import ProjectDetailsStats from '../views/ProjectDetailsStats.vue';
import ProjectDetailsConfig from '../views/ProjectDetailsConfig.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/public/:projectId/:invitationId/:projectName',
        name: 'PublicInvitationName',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
        }),
    },
    {
        path: '/public/:projectId/:invitationId',
        name: 'PublicInvitation',
        component: PublicInvitation,
        props: () => ({
            isSimple: false,
        }),
    },
    {
        path: '/ps/:projectId/:projectName',
        name: 'PublicInvitationSimple',
        component: PublicInvitation,
        props: () => ({
            isSimple: true,
        }),
    },
    {
        path: '/admin',
        name: 'admin',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
    },
    {
        path: '/userConfig',
        name: 'userConfig',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/UserConfig.vue'),
    },
    {
        path: '/UserConfigSettings',
        name: 'userConfigSettings',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */ '../views/UserConfigSettings.vue'
            ),
    },
    {
        path: '/projects',
        name: 'projects',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue'),
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    },
    {
        path: '/register',
        name: 'register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/RegisterView.vue'),
    },
    {
        path: '/project/:projectId/:projectName',
        name: 'ProjectDetails',
        component: ProjectDetails,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectName/invitati',
        name: 'ProjectDetailsInvitations',
        component: ProjectDetailsInvitations,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectName/config',
        name: 'ProjectDetailsConfig',
        component: ProjectDetailsConfig,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/project/:projectId/:projectName/statistici',
        name: 'ProjectDetailsStats',
        component: ProjectDetailsStats,
        props: true, // This allows passing route params as props to the component
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
    },
    {
        path: '/addProject',
        name: 'addProject',
        beforeEnter: (to, from, next) => {
            const isAuthenticated = checkAuthentication(); // Implement your authentication check
            if (isAuthenticated) {
                next(); // Allow access
            } else {
                next('/login'); // Redirect to the home page or login page if not authenticated
            }
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AddProject.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

export default router;
