<template>
    <div
        v-if="cookieAccepted != 'accepted'"
        class="bg-slate-200 fixed bottom-0 z-30 w-full py-5 px-3"
    >
        <div class="flex items-center justify-center flex-col md:flex-row">
            <p class="text-sm mb-5 md:mb-0">
                Acest site utilizează module cookie pentru a vă asigura cea mai
                bună experiență. Prin continuarea utilizării, sunteți de acord
                cu modulele cookie conform politicii noastre de
                confidențialitate.
            </p>
            <button @click="handleCookieStore" class="btn btn-secondary ml-5">
                Înțeleg și accept
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookieNotification',
    data() {
        return {
            cookieAccepted: false,
        };
    },
    created() {
        this.cookieAccepted = localStorage.getItem('userCookieAccept');
    },
    methods: {
        handleCookieStore() {
            localStorage.setItem('userCookieAccept', 'accepted');
            this.cookieAccepted = 'accepted';
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
