<template>
    <div>
        <div v-if="isAuthenticated">
            <button
                @click="logout"
                class="font-medium tracking-wide py-2 px-5 sm:px-8 border border-orange-500 text-orange-500 bg-white-500 outline-none rounded-l-full rounded-r-full capitalize hover:bg-orange-500 hover:text-white transition-all hover:shadow-orange"
            >
                <font-awesome-icon
                    icon="fa-solid fa-right-from-bracket"
                    class="block md:hidden"
                /><span class="hidden md:block">Logout</span>
            </button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/utils/EventBus';

export default {
    data() {
        return {
            isAuthenticated: false,
        };
    },
    created() {
        // Check if the user is already authenticated (e.g., on page reload)
        this.isAuthenticated = this.checkAuthentication();

        // You may want to add a global event listener to listen for authentication changes
        window.addEventListener('storage', this.handleStorageEvent);
    },
    methods: {
        async logout() {
            // Retrieve user_id from localStorage
            const token = localStorage.getItem('userToken');

            // Set up the Authorization header
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            // Make a POST request to the server endpoint for logout
            axios
                .post('/api/logout.php', null, { headers })
                .then(() => {
                    // Clear the authentication token from localStorage
                    localStorage.removeItem('userToken');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('ownedProjects');
                    localStorage.removeItem('userRole');
                    localStorage.removeItem('userActive');
                    localStorage.removeItem('tokenInvalid');

                    this.$store.dispatch('logout'); // Dispatch logout action

                    this.isAuthenticated = false;
                    eventBus.emit('LogoutSuccessful');
                    this.$router.push('/login');
                })
                .catch((error) => {
                    // Handle error (e.g., server error or failed request)
                    console.error('Logout failed:', error);
                });
        },
        checkAuthentication() {
            // Check if the authentication token is present in localStorage
            const token = localStorage.getItem('userToken');
            return !!token; // Returns true if the token is present, otherwise false
        },

        handleStorageEvent(event) {
            // Listen for changes in localStorage (e.g., logout in another tab)
            if (event.key === 'userToken') {
                this.isAuthenticated = this.checkAuthentication();
            }
        },
    },
};
</script>

<style scoped>
/* No need to add additional styles here for Tailwind CSS */
</style>
