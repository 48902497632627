<template>
    <div class="footer border-t py-20 mt-20">
        <div
            class="max-w-screen-xl w-full mx-auto grid grid-rows-6 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4"
        >
            <div
                class="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col items-start"
            >
                <svg
                    version="1.2"
                    baseProfile="tiny"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 509.3 140.4"
                    overflow="visible"
                    xml:space="preserve"
                    class="h-14 w-auto"
                >
                    <g>
                        <circle
                            fill="none"
                            stroke="#F15A35"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            cx="76.4"
                            cy="96.7"
                            r="4.8"
                        />

                        <path
                            fill="none"
                            stroke="#F15A35"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            d="
M80.3,93.8c11.1-6.4,18.6-18.5,18.3-32.4c-0.4-19.8-17-35.8-36.8-35.6c-19.9,0.2-36,16.4-36,36.4c0,20.1,15.9,36.4,36,36.4v6.2
c0,2.2-1.8,4-4,4h-5.6c-2.9,0-5.2,2.3-5.2,5.2l0,0c0,2.9,2.3,5.2,5.2,5.2h17.1c2.8,0,5,2.2,5,5l0,0c0,2.8-2.2,5-5,5h-7.5v6.2"
                        />

                        <line
                            fill="none"
                            stroke="#F15A35"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            x1="61.8"
                            y1="70.7"
                            x2="61.8"
                            y2="98.6"
                        />
                    </g>

                    <g>
                        <line
                            fill="none"
                            stroke="#EFB237"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            x1="61.8"
                            y1="17.7"
                            x2="61.8"
                            y2="8.8"
                        />

                        <line
                            fill="none"
                            stroke="#EFB237"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            x1="30.1"
                            y1="30.8"
                            x2="23.9"
                            y2="24.5"
                        />

                        <line
                            fill="none"
                            stroke="#EFB237"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            x1="17"
                            y1="62.5"
                            x2="8.1"
                            y2="62.5"
                        />

                        <line
                            fill="none"
                            stroke="#EFB237"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            x1="93.5"
                            y1="30.8"
                            x2="99.7"
                            y2="24.5"
                        />

                        <line
                            fill="none"
                            stroke="#EFB237"
                            stroke-width="5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-miterlimit="10"
                            x1="106.6"
                            y1="62.5"
                            x2="115.5"
                            y2="62.5"
                        />

                        <circle
                            fill="none"
                            stroke="#EFB237"
                            stroke-width="5"
                            stroke-miterlimit="10"
                            cx="61.8"
                            cy="61.5"
                            r="9.3"
                        />
                    </g>

                    <g>
                        <path d="M138,90.9V55.2h7.9v35.7H138z" />

                        <path
                            d="M171.3,90.9V75.6c0-3.7-1.7-5.6-4.7-5.6c-3,0-4.9,1.9-4.9,5.6v15.3H154V63.5h7.8v3.9c1.8-2.3,4.1-4.4,8-4.4
c5.9,0,9.3,3.9,9.3,10.2v17.7H171.3z"
                        />

                        <path
                            d="M200.2,91.1h-7l-10.8-27.6h8.2l6.1,18.3l6.2-18.3h8.1L200.2,91.1z"
                        />

                        <path
                            d="M215.6,60.5v-6.9h8.2v6.9H215.6z M215.8,90.9V63.5h7.8v27.4H215.8z"
                        />

                        <path
                            d="M240.1,91.4c-4.7,0-8-1.9-8-8.2v-13h-3.3v-6.6h3.3v-7h7.8v7h6.4v6.6h-6.4v11.7c0,1.8,0.8,2.7,2.5,2.7c1.4,0,2.7-0.4,3.8-1
v6.2C244.6,90.8,242.7,91.4,240.1,91.4z"
                        />

                        <path
                            d="M268.1,90.9v-3c-1.9,2.1-4.5,3.5-8.3,3.5c-5.2,0-9.4-3-9.4-8.4v-0.1c0-6,4.5-8.7,11-8.7c2.8,0,4.7,0.5,6.7,1.1v-0.5
c0-3.2-2-5-5.9-5c-3,0-5.1,0.6-7.6,1.5l-1.9-5.9c3-1.3,6-2.2,10.6-2.2c8.5,0,12.2,4.4,12.2,11.8v15.9H268.1z M268.2,79.9
c-1.3-0.6-3.1-1-5-1c-3.3,0-5.4,1.3-5.4,3.8v0.1c0,2.1,1.7,3.3,4.2,3.3c3.6,0,6.1-2,6.1-4.8V79.9z"
                        />

                        <path
                            d="M291.3,91.4c-4.7,0-8-1.9-8-8.2v-13H280v-6.6h3.3v-7h7.8v7h6.4v6.6h-6.4v11.7c0,1.8,0.8,2.7,2.5,2.7c1.4,0,2.7-0.4,3.8-1
v6.2C295.7,90.8,293.9,91.4,291.3,91.4z"
                        />

                        <path
                            d="M303.4,60.5v-6.9h8.2v6.9H303.4z M303.6,90.9V63.5h7.8v27.4H303.6z"
                        />

                        <path
                            d="M318.8,60.5v-6.9h8.2v6.9H318.8z M319,90.9V63.5h7.8v27.4H319z"
                        />

                        <path
                            d="M346.3,85.6l5.5-6.6c3.5,2.8,7.5,4.3,11.6,4.3c2.7,0,4.1-0.9,4.1-2.5v-0.1c0-1.5-1.2-2.3-6-3.4
c-7.6-1.7-13.5-3.9-13.5-11.2V66c0-6.6,5.3-11.4,13.8-11.4c6.1,0,10.8,1.6,14.7,4.7l-5,7c-3.3-2.3-6.8-3.5-10-3.5
c-2.4,0-3.6,1-3.6,2.3v0.1c0,1.6,1.2,2.3,6.2,3.5c8.2,1.8,13.3,4.4,13.3,11.1v0.1c0,7.3-5.8,11.6-14.4,11.6
C356.8,91.5,350.7,89.5,346.3,85.6z"
                        />

                        <path
                            d="M381.8,63.3h9.7v3.8c1.8-2.3,4.3-4.4,8.2-4.4c3.7,0,6.5,1.6,8,4.5c2.4-2.8,5.2-4.5,9.2-4.5c5.9,0,9.4,3.7,9.4,10.1v18.1
h-9.7v-15c0-3-1.5-4.6-3.8-4.6c-2.3,0-4,1.6-4,4.6v15h-9.7v-15c0-3-1.5-4.6-3.8-4.6c-2.3,0-3.9,1.6-3.9,4.6v15h-9.7V63.3z"
                        />

                        <path
                            d="M430,83v-0.1c0-5.7,4.3-8.7,10.8-8.7c2.6,0,5.1,0.5,6.6,1.1v-0.5c0-2.9-1.8-4.5-5.5-4.5c-2.9,0-5.2,0.6-7.6,1.5l-2-6.8
c3.1-1.3,6.4-2.1,11.2-2.1c5,0,8.3,1.2,10.5,3.4c2.1,2,3,4.9,3,8.8v15.9h-9.6V88c-1.9,2.1-4.5,3.5-8.1,3.5
C434,91.5,430,88.5,430,83z M447.5,80.9v-1.3c-1-0.4-2.3-0.7-3.8-0.7c-2.8,0-4.4,1.4-4.4,3.4v0.1c0,1.9,1.4,3,3.4,3
C445.6,85.4,447.5,83.7,447.5,80.9z"
                        />

                        <path
                            d="M461.9,63.3h9.7v5.6c1.6-3.8,4.1-6.2,8.7-6V73h-0.8c-5.1,0-7.9,2.9-7.9,9.3v8.6h-9.7V63.3z"
                        />

                        <path
                            d="M485.5,82.3V71.1h-3.3v-7.8h3.3v-7h9.7v7h6.4v7.8h-6.4v9.3c0,1.9,0.9,2.8,2.7,2.8c1.3,0,2.5-0.4,3.7-0.9v7.6
c-1.7,1-4,1.6-6.7,1.6C489,91.5,485.5,88.9,485.5,82.3z"
                        />
                    </g>
                </svg>

                <p class="mb-4">
                    <strong class="font-medium">Invitatii Smart</strong>
                    e un serviciu inteligent pentru organizarea si monitorizarea
                    invitatiilor.
                </p>

                <div class="flex w-full mt-2 mb-8 -mx-2 hidden">
                    <div
                        class="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md"
                    >
                        <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <path fill="none" d="M0 0h24v24H0z"></path>

                            <path
                                d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"
                                fill="rgba(245,56,56,1)"
                            ></path>
                        </svg>
                    </div>

                    <div
                        class="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md"
                    >
                        <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <path fill="none" d="M0 0h24v24H0z"></path>

                            <path
                                d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
                                fill="rgba(245,56,56,1)"
                            ></path>
                        </svg>
                    </div>

                    <div
                        class="mx-2 bg-white-500 rounded-full items-center justify-center flex p-2 shadow-md"
                    >
                        <svg
                            class="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                        >
                            <path fill="none" d="M0 0h24v24H0z"></path>

                            <path
                                d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                                fill="rgba(245,56,56,1)"
                            ></path>
                        </svg>
                    </div>
                </div>

                <p class="text-gray-400">
                    ©
                    <!-- -->
                    2024
                    <!-- -->
                    - InvitatiiSmart
                </p>
            </div>

            <div
                class="row-span-2 sm:col-span-2 sm:col-start-7 sm:col-end-9 flex flex-col hidden"
            >
                <p class="text-black-600 mb-4 font-medium text-lg">Product</p>

                <ul class="text-black-500">
                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Download
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Pricing
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Locations
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Server
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Countries
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Blog
                        <!-- -->
                    </li>
                </ul>
            </div>

            <div
                class="row-span-2 sm:col-span-2 sm:col-start-9 sm:col-end-11 flex flex-col hidden"
            >
                <p class="text-black-600 mb-4 font-medium text-lg">Engage</p>

                <ul class="text-black-500">
                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        InvitatiiSmart ?
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        FAQ
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Tutorials
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        About Us
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Privacy Policy
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Terms of Service
                        <!-- -->
                    </li>
                </ul>
            </div>

            <div
                class="row-span-2 sm:col-span-2 sm:col-start-11 sm:col-end-13 flex flex-col hidden"
            >
                <p class="text-black-600 mb-4 font-medium text-lg">
                    Earn Money
                </p>

                <ul class="text-black-500">
                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Affiliate
                        <!-- -->
                    </li>

                    <li
                        class="my-2 hover:text-orange-500 cursor-pointer transition-all"
                    >
                        Become Partner
                        <!-- -->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
