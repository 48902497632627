<template>
    <div
        class="backdrop fixed w-screen h-screen bg-white z-50 top-0 right-0 left-0 bottom-0 opacity-90 flex justify-center"
    >
        <span class="loading loading-ring loading-lg"></span>
    </div>
</template>

<script>
export default {
    name: 'LoadingComponent',
    data() {
        return {};
    },
};
</script>
