<template>
    <UserTopNav :active="'projects'" />
    <div class="max-w-screen-xl w-full mx-auto">
        <router-link to="/projects"
            ><button
                class="my-10 font-bold bg-slate-500 hover:bg-slate-700 text-white shadow-xl transition px-6 py-2 rounded-full"
            >
                <font-awesome-icon icon="fa-solid fa-arrow-left" />
                Inapoi la Proiecte
            </button></router-link
        >

        <div class="border p-5 md:p-10 rounded-3xl bg-neutral-content">
            <h1 class="font-extralight text-4xl mb-20">
                {{ currentProjectName }}
            </h1>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-10 transition">
                <router-link
                    :to="{
                        name: 'ProjectDetailsConfig',
                        params: {
                            projectId: currentProjectId,
                            projectName: currentProjectName,
                        },
                    }"
                >
                    <div
                        class="card md:w-96 bg-slate-400 text-primary-content glass shadow-md hover:shadow-2xl hover:bg-slate-500 transition duration-300"
                    >
                        <div class="card-body">
                            <h2 class="card-title">Proiectul tau</h2>
                            <p class="text-left drop-shadow-md">
                                Setari generale
                            </p>
                        </div>
                        <font-awesome-icon
                            icon="fa-solid fa-sliders"
                            class="absolute text-6xl right-8 top-8 opacity-25"
                        />
                    </div>
                </router-link>

                <router-link
                    :to="{
                        name: 'ProjectDetailsInvitations',
                        params: {
                            projectId: currentProjectId,
                            projectName: currentProjectName,
                        },
                    }"
                >
                    <div
                        class="card md:w-96 bg-blue-400 text-primary-content glass shadow-md hover:shadow-2xl hover:bg-blue-500 transition duration-300"
                    >
                        <div class="card-body">
                            <h2 class="card-title">Invitati</h2>
                            <p class="text-left drop-shadow-md">
                                Modifica sau adauga invitati
                            </p>
                        </div>

                        <font-awesome-icon
                            icon="fa-solid fa-users-gear"
                            class="absolute text-6xl right-8 top-8 opacity-25"
                        />
                    </div>
                </router-link>

                <router-link
                    :to="{
                        name: 'ProjectDetailsStats',
                        params: {
                            projectId: currentProjectId,
                            projectName: currentProjectName,
                        },
                    }"
                >
                    <div
                        class="card md:w-96 bg-primary text-primary-content glass shadow-md hover:shadow-2xl hover:bg-green-500 transition duration-300"
                    >
                        <div class="card-body">
                            <h2 class="card-title">Statistici</h2>
                            <p class="text-left drop-shadow-md">
                                Vezi in timp real raspunsurile invitatilor
                            </p>
                        </div>

                        <font-awesome-icon
                            icon="fa-solid fa-chart-column"
                            class="absolute text-6xl right-8 top-8 opacity-25"
                        />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import UserTopNav from '@/components/UserTopNav.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'AdminView',
    components: { UserTopNav },
    props: {
        projectId: {},
        projectName: {
            type: String,
        },
    },
    data() {
        return {
            currentProjectId: null,
            currentProjectName: null,
        };
    },
    computed: {
        ...mapGetters(['user', 'projects', 'credit', 'plan', 'loading']),
    },
    created() {
        // Access the current route from the Vue Router
        const currentRoute = this.$route;

        // Access the project ID from the route parameters
        this.currentProjectId = currentRoute.params.projectId;

        this.currentProjectName = currentRoute.params.projectName;
    },
    mounted() {
        if (!this.projects) {
            this.fetchUserDetails();
        }
    },
    methods: {
        ...mapActions(['fetchUserDetails']),
    },
};
</script>
