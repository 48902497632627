<template>
    <div class="top-5 md:top-10 left-3 md:left-10 fixed z-50">
        <div class="tooltip tooltip-top" data-tip="Meniu Editare">
            <button
                class="btn btn-circle text-lg btn-secondary relative"
                :class="isEditMode ? 'btn-success' : ''"
                @click="toggleEditMode"
            >
                <font-awesome-icon v-if="isEditMode" icon="fa-solid fa-xmark" />
                <font-awesome-icon v-else icon="fa-solid fa-edit" />
            </button>
            <button
                v-if="!isEditMode"
                class="-top-2 -left-2 w-16 loading loading-ring absolute"
                :style="{ backgroundColor: currentColor }"
            ></button>
        </div>
    </div>
    <!-- <button @click="toggleEditMode">
        {{ editMode ? 'Exit Edit Mode' : 'Enter Edit Mode' }}
    </button> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            currentColor: '#ffffff', // Initial color
        };
    },
    computed: {
        ...mapGetters(['isEditMode']),
        editMode() {
            return this.isEditMode;
        },
    },
    methods: {
        ...mapActions(['toggleEditMode']),
        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        changeColor() {
            this.currentColor = this.getRandomColor();
        },
    },
    mounted() {
        this.colorInterval = setInterval(this.changeColor, 500);
    },
    beforeUnmount() {
        clearInterval(this.colorInterval);
    },
};
</script>

<style scoped>
button {
    transition: background-color 1s ease;
}
</style>
