<template>
    <div class="px-2 md:px-0">
        <ul
            class="menu menu-horizontal bg-base-200 rounded-box my-6 w-full md:max-w-screen-xl text-xl"
        >
            <li>
                <router-link
                    :to="{
                        name: 'userConfig',
                    }"
                    class="tooltip"
                    data-tip="Contul tau"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-house-chimney-user "
                        :class="{
                            'text-secondary': active == 'home',
                        }"
                    /><span class="ml-2 text-base hidden md:inline-block"
                        >Contul tau</span
                    >
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{
                        name: 'projects',
                    }"
                    class="tooltip"
                    data-tip="Proiecte"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-layer-group"
                        :class="{
                            'text-secondary': active == 'projects',
                        }"
                    />
                    <span class="ml-2 text-base hidden md:inline-block"
                        >Proiecte</span
                    >
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{
                        name: 'userConfigSettings',
                    }"
                    class="tooltip"
                    data-tip="Setari"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-sliders"
                        :class="{
                            'text-secondary': active == 'settings',
                        }"
                    />
                    <span class="ml-2 text-base hidden md:inline-block"
                        >Setari</span
                    >
                </router-link>
            </li>

            <li class="ml-auto">
                <a
                    v-if="user && user.user_active == 0"
                    class="tooltip tooltip-left tooltip-error"
                    data-tip="Cont cu acces limitat"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-arrow-down-up-lock"
                        class="text-error"
                    />
                </a>

                <a v-else class="tooltip tooltip-left" data-tip="Cont activ">
                    <font-awesome-icon
                        icon="fa-solid fa-arrow-down-up-across-line"
                        class="text-success"
                    />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'UserTopNav',
    props: {
        active: {
            type: String,
        },
    },
    components: {},
    data() {
        return { userActive: 0 };
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {},
    methods: {},
};
</script>
