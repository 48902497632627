export function checkAuthentication() {
    const token = localStorage.getItem('userToken');

    return !!token;
}

export function handleDeauth() {
    // this.$store.dispatch('logout'); // Dispatch logout action
    localStorage.clear();
    // vm.$router.push('/login');
}
